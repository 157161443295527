import { graphql } from "gatsby";
import * as React from "react";
import ContentsMenu from "../components/ContentsMenu";
import Layout from "../components/layout";
import theme from "../styles/theme";

const TechDescPage = ({ location, data }) => {
  return (
    <Layout
      location={location}
      seo={data.doc.seoMetaTags}
      logo={data.global.logo}
    >
      <main
        css={`
          margin: 0 auto 180px;
          padding-bottom: 0;
          min-height: 100vh;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 10px;
          ${theme.tablet} {
            padding-top: 40px;
          }

          .intro {
            h1 {
              margin-top: 100px;
              font-size: 64px;
              margin-bottom: 20px;
              max-width: 800px;
              ${theme.laptop} {
                font-size: 58px;
                margin-top: 80px;
              }
              ${theme.tablet} {
                margin-top: 10px;
              }
              ${theme.mobile} {
                font-size: 36px;
                margin-top: 10px;
              }
            }
            p {
              max-width: 450px;
              font-size: 18px;
              line-height: 120%;
              ${theme.mobile} {
                font-size: 18px;
              }
            }
            svg {
              margin-top: 30px;
            }
          }

          .content {
            padding-left: 100px;
            width: 100%;
            box-sizing: border-box;
            p {
              font-size: 18px;
              line-height: 1.3;
            }

            img {
              margin: 20px auto;
              width: 100%;
            }
            h2 {
              margin-top: 40px;
              font-weight: 900;
              font-size: 22px;
              line-height: 120%;
              color: #474747;
            }
            ul,
            ol {
              font-size: 18px;
              line-height: 1.5;
            }
            li::marker {
              color: ${theme.pink};
            }
            blockquote {
              margin-block-start: 0;
              margin-block-end: 0;
              margin-inline-start: 0;
              margin-inline-end: 0;
              font-size: 16px;
              margin-bottom: 30px;
              box-shadow: 4px 4px 20px rgb(0 0 0 / 25%);
              padding: 30px 40px 50px;
              border-radius: 10px;
              strong {
                color: #000154;
              }
            }
          }
          ${theme.max1000} {
            .content {
              padding-left: 0;
            }
          }
        `}
      >
        <section
          css={`
            margin-top: 100px;
            flex-direction: column;
            margin-bottom: 20px;
          `}
        >
          <div className="intro">
            <h1>{data.global.tdTitle}</h1>
            <p>{data.global.tdLead}</p>
            <svg
              width="63"
              height="63"
              viewBox="0 0 63 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d)">
                <circle
                  cx="31.5"
                  cy="29.6309"
                  r="27.0417"
                  transform="rotate(90 31.5 29.6309)"
                  stroke="#474747"
                  stroke-width="4.91667"
                />
                <path
                  d="M44.9725 21.2545L33.3305 40.611C32.3752 42.1994 30.0725 42.1994 29.1172 40.611L17.4753 21.2545"
                  stroke="#474747"
                  stroke-width="4.91667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d"
                  x="0.361111"
                  y="0.130859"
                  width="62.2778"
                  height="62.2778"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dy="1.63889" />
                  <feGaussianBlur stdDeviation="0.819444" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </section>
        <section
          css={`
            display: flex;
            ${theme.max1000} {
              flex-direction: column;
            }
          `}
        >
          <aside>
            <ContentsMenu
              data={data.allTechDocLinks.edges}
              slug={data.doc.slug}
            />
          </aside>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: data.doc.content }}
          />
        </section>
      </main>
    </Layout>
  );
};

export default TechDescPage;

export const query = graphql`
  query TechDocQuery($slug: String!) {
    doc: datoCmsTechnicalDoc(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      content
    }
    allTechDocLinks: allDatoCmsTechnicalDoc(filter: { root: { eq: true } }) {
      edges {
        node {
          slug
          title
          treeChildren {
            title
            slug
          }
        }
      }
    }
    global: datoCmsGlobal {
      tdTitle
      tdLead
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
